import React from 'react';
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {Box, Stack, Typography} from "@mui/material";

const PricesPage = () => {
  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader/>

      <Box sx={{...wrapperSx}}>
        <Stack
          direction={'column'}
          width={'100%'}
          sx={{
            mt: '38px',
            p: '52px',
            borderRadius: '24px',
            background: '#F5F5F5',
            gap: '16px'
          }}
        >
          <Typography
            variant={'h6'}
          >
            Стоимость услуг
          </Typography>
          <Typography>
            Услуги защищенного хранения базы данных в облаке - 1000 тенге
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default PricesPage;