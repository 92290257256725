import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Box,
  Stack, TextField,
  Typography
} from '@mui/material';
import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from "../../global/helpers/globalstyles";
import LoadingButton from "../../global/components/LoadingButton";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {apiGetStudentsByIin} from "../actions/api";
import {StudentInfoType} from "../../global/actions/types.api";
import StudentGroupCard from "../../Main/components/StudentGroupCard";

const FeePage = () => {

  const navigate = useNavigate();

  const [iin, setIin] = useState<string>('');

  const [studentsByIIN, studentsByIINLoading, studentsByIINError, fetchStudentsByIIN, resetStudentsByIIN] = useLoadingHook(apiGetStudentsByIin);

  useEffect(() => {
  }, []);

  const handleIOKAClick = () => {

  }

  useEffect(() => {

  }, []);

  const handleIinChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIin(e.currentTarget.value);
  }

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader/>

      <Box sx={{...wrapperSx}}>
        <Stack
          direction={'column'}
          width={'100%'}
          sx={{
            mt: '38px',
            p: '52px',
            borderRadius: '24px',
            background: '#F5F5F5',
            gap: '16px'
          }}
        >
          <Typography
            variant={'h6'}
          >
            Оплата пошлины за хранение учебных данных
          </Typography>

          {/*<Tabs>*/}

          {/*</Tabs>*/}

          <Stack
            flexDirection={'column'}
            gap={2}
          >
            <TextField
              label={'ИИН'}
              name={'iin'}
              value={iin}
              sx={{
                width: 'fit-content',
              }}
              onChange={handleIinChange}
            />

            <LoadingButton
              variant={'contained'}
              sx={{
                width: 'fit-content',
                px: '50px'
              }}
              disabled={!iin}
              loading={studentsByIINLoading}
              onClick={() => {
                fetchStudentsByIIN(iin)
              }}
            >
              Проверить
            </LoadingButton>
          </Stack>

          {studentsByIIN && (
            <Stack
              flexDirection={'row'}
              flexWrap={'wrap'}
              gap={1}
            >
              {studentsByIIN.length > 0 && studentsByIIN.map((student: StudentInfoType) => {
                return (
                  <StudentGroupCard
                    studentInfo={student}
                    handleStartExam={() => {}}
                    feePage={false}
                    quickFeePage={true}
                    attachmentModule={{is_enabled: true}}
                    key={student.id}
                  />
                )
              })}
              {studentsByIIN.length === 0 && (
                <Typography>
                  По данному ИИН не найдено неоплаченных пошлин
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default FeePage;
